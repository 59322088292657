.container {
  color: white;
  padding: 10px;
}

.button {
  margin: 20px auto 0 auto;
  width: 200px;
  height: 40px;
  background: #5454de;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: blue;
  }
}

.playTimesTableContainer {
  max-width: 100%;
  overflow: auto;
}

.playTimesKey {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.playTimesTable {
  margin: 0 auto;
  //border-collapse: collapse;
}

.utcRow {
  background-color: red;
}

.localTimeRow {
  background-color: blue;
}

.dayRow {
  border: 1px solid;
}

.dayCell {
  width: 56px;
  height: 30px;
  text-align: right;
  padding-right: 4px;
}

th.timeCell {
  color: white;
}

.timeCell {
  width: 30px;
  height: 30px;
  text-align: center;
  color: black;

  &.localTime {
    line-height: 1;

    span {
      display: block;
    }

    small {
      font-size: 0.7em;
      display: block;
    }
  }
}

[data-level='0'] {
  background-color: #3b3b3b;
  color: #656565;
}

[data-level='1'] {
  background-color: #c6e48b;
}

[data-level='2'] {
  background-color: #7bc96f;
}

[data-level='3'] {
  background-color: #196127;
}

[data-level='4'] {
  background-color: #05f133;
}

.groupActivities {
  max-width: 800px;
  margin: 20px auto;

  h1 {
    text-align: center;
  }
}

.activitiesKey {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.keyLabel {
  display: flex;
  align-items: center;
  gap: 5px;
}

.activityName {
  text-align: right;
}

.activityCounts {
  width: 400px;
}

.nonLethal,
.lethal {
  height: 20px;
}

.nonLethal {
  color: black;
  font-weight: bold;
  padding: 2px;
  background-color: yellow;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.lethal {
  color: black;
  font-weight: bold;
  padding: 3px;
  background-color: red;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.otherIdeas {
  max-width: 600px;
  margin: 0 auto;
}

.speechBubble {
  position: relative;
  background: #ebebeb;
  border-radius: 0.4em;
  margin-bottom: 40px;
  color: black;
  padding: 10px;

  .name {
    position: absolute;
    bottom: -25px;
    right: 55px;
    color: white;
  }
}

.speechBubble:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 20px;
  width: 0;
  height: 0;
  border: 27px solid transparent;
  border-top-color: #ebebeb;
  border-bottom: 0;
  border-left: 0;
  margin-left: -13.5px;
  margin-bottom: -27px;
}
