.titleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e1e1e;
  padding: 5px 10px;
  border-radius: 10px;
}

@container (max-width: 768px) {
  .titleBox {
    flex-direction: column;
    gap: 10px;
  }
}
