.playTimesTableContainer {
  max-width: 100%;
  overflow: auto;
}

.playTimesKey {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.keyLabel {
  display: flex;
  align-items: center;
  gap: 5px;
}

.playTimesTable {
  margin: 0 auto;
  //border-collapse: collapse;
}

.utcRow {
  background-color: red;
}

.localTimeRow {
  background-color: blue;
}

.dayRow {
  border: 1px solid;
}

.dayCell {
  width: 56px;
  height: 30px;
  text-align: right;
  padding-right: 4px;
}

th.timeCell {
  color: white;
}

.timeCell {
  width: 30px;
  height: 30px;
  text-align: center;
  color: black;

  &.localTime {
    line-height: 1;

    span {
      display: block;
    }

    small {
      font-size: 0.7em;
      display: block;
    }
  }
}

[data-level='0'] {
  background-color: #3b3b3b;
  color: #656565;
}

[data-level='1'] {
  background-color: #c6e48b;
}

[data-level='2'] {
  background-color: #7bc96f;
}

[data-level='3'] {
  background-color: #196127;
}

[data-level='4'] {
  background-color: #05f133;
}
