.outerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 2fr 1fr;

  @media screen and (max-width: 1025px) {
    & {
      grid-template-columns: 1fr;
    }
  }
}

.left {
  flex-grow: 2;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.left section {
  background-color: rgba(0, 0, 0, 0.7);
}

.hero {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 9px;
  padding: 30px;

  //padding: 40px 10px 20px 10px;
  border-image-slice: 21 21 21 21;
  border-image-width: 20px 20px 20px 20px;
  border-image-outset: 9px 9px 9px 9px;
  border-image-repeat: stretch stretch;
  border-image-source: url('../../../../public/box-borders.png');
  border-style: solid;

  h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1024px) {
    & {
      flex-direction: column;
    }
  }
}

.right {
  flex-grow: 1;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 1025px) {
    & {
      display: none;
    }
  }
}

.discordBlock {
  width: 200px;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 10px;
}

.discord {
  width: 200px;
  flex-shrink: 0;
  text-align: center;
  font-size: 1.4em;
  background-color: #5865f2;
  border-radius: 20px;
  padding: 14px 20px 5px 20px;
  color: white;
  text-decoration: none;
  display: block;
  text-transform: capitalize;
  font-family: 'Ginto Nord', sans-serif;
  font-weight: bold;
  transition:
    box-shadow 0.3s ease-in-out,
    background 0.3s ease-in-out,
    transform 0.3s ease-in-out;

  p {
    margin: 0;
  }

  &:hover {
    background: #6572ff;
    transform: translate(0, -3px);
    box-shadow: #333b8d 0 4px 0 0;
  }

  &:active {
    transition:
      box-shadow 0.1s ease-in-out,
      background 0.1s ease-in-out,
      transform 0.1s ease-in-out;
    background: #292f70;
    transform: translate(0, 3px);
    box-shadow: #333b8d 0 -4px 0 0;
  }
}

.discordCounters {
  display: flex;
  align-items: center;
  gap: 10px;
}

.counter {
  display: flex;
  align-items: center;
}

.counterName {
  background-color: rgba(172, 15, 15, 1);
  color: white;
  padding: 3px 8px 3px 4px;
  font-size: 0.8em;
  display: inline-block;
  border: 1px solid black;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  border-right: 0;
}

.counterCount {
  background-color: #f6ce06;
  font-weight: bold;
  color: black;
  padding: 3px 6px 3px 8px;
  font-size: 0.7em;
  display: inline-block;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  border-left: 0;
}

.guildHighlights {
  margin: 0;

  //li::marker {
  //  content: '» ';
  //}
  //
  //ul li::marker {
  //  content: 'o ';
  //}
}

.details {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: 1025px) {
    &.mobileDetails {
      display: none;
    }
  }
}

.activities {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;

  @media screen and (max-width: 800px) {
    & {
      gap: 40px;
    }
  }
}

.activity {
  display: flex;
  align-items: center;
  gap: 30px;

  @media screen and (max-width: 800px) {
    & {
      flex-direction: column;
      gap: 10px;
    }
  }
}

.activityImage {
  display: flex;
  flex-shrink: 0;

  img {
    height: 150px;
  }
}
