.settingsContainer {
  display: grid;
  grid-template-columns: auto min-content;
  row-gap: 16px;
  column-gap: 16px;
}

@container (max-width: 1200px) {
  .settingsContainer {
    grid-template-columns: auto;
  }

  .playTimeSettings {
    overflow: auto;
  }
}

.playTimesTable {
  min-width: calc(24 * 30px + 56px);
}

.utcRow {
  background-color: red;
}

.localTimeRow {
  background-color: blue;
}

.dayRow {
}

.dayCell {
  width: 56px;
  height: 30px;
  text-align: right;
  padding-right: 4px;
}

th.timeCell {
  color: white;
}

.timeCell {
  min-width: 30px;
  max-width: 30px;
  width: 30px;
  height: 30px;
  text-align: center;
  color: black;

  input {
    display: none;
  }

  &.localTime {
    line-height: 1;

    span {
      display: block;
    }

    small {
      font-size: 0.7em;
      display: block;
    }
  }
}

.dayTimeCell {
  background-color: rgba(87, 86, 86, 0.5);
  cursor: pointer;
  transition: background-color 0.1s;

  &:hover {
    background-color: #f0f0f0;
  }

  &:has(input:checked) {
    background-color: #a0dd2e;

    &:hover {
      background-color: #7bc96f;
    }
  }
}

.available {
  background-color: #a0dd2e;
}

.notAvailable {
  background-color: #575656;
}

.activitiesGrid {
  display: grid;
  grid-template-columns: auto 100px 100px;
  row-gap: 8px;
  column-gap: 3px;
  max-width: 600px;
}

@container (max-width: 1200px) {
  //.activitiesGrid {
  //  grid-template-columns: auto 100px;
  //}
}

.activityRow {
  display: contents;

  &:hover div {
    background-color: #575656;
  }

  div {
    background-color: #4a4949;
    display: flex;
    align-items: center;
    padding: 2px;

    &:has(input:checked) {
      background-color: #4f6c16;
    }

    &:first-child {
      padding-left: 20px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.checkboxColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}
