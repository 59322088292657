.container {
  padding: 10px 20px;
}

.newCompRow {
  text-align: center;
}

.newComp {
  color: white;
  text-decoration: none;
  background-color: #4a51bb;
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 0;
  width: 100%;

  &:hover {
    background-color: #5b64e1;
  }
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: max-content;
  border-radius: 10px;

  th,
  td {
    border-top: 2px solid #2d2d2d;
    padding: 10px;
  }

  th {
    background-color: #000000;
    text-align: left;

    &:first-child {
      border-top-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
    }
  }

  td {
    background-color: #1e1e1e;
  }

  tr:nth-child(even) td {
    //background-color: #4f4f4f;
  }
}

@container (max-width: 768px) {
  .table {
    width: 100%;
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    background-color: #4a51bb;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    gap: 5px;

    &:hover {
      background-color: #5b64e1;
    }
  }
}
