.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .left {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.controlButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px 10px 10px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  transition: background-color 0.2s;
}

.endEventButton {
  .controlButton();

  background-color: #ff4d4f;
  color: white;

  &:hover {
    background-color: #ff7875;
  }
}

.silverInput {
  width: 90px;
  font-size: 0.875rem;
  border: 0;
  background-color: transparent;
  border-bottom: 1px dotted gray;

  &:focus {
    outline: none;
    border-bottom-color: lightgray;
  }

  &:hover {
    border-bottom-color: white;
  }
}
