.imagesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 500px;
  overflow: auto;
}

.image {
  border: 2px solid transparent;
  max-width: 30%;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  &.selected {
    border-color: white;
  }
}
