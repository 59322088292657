.image {
  max-width: 100%;
  width: auto;
  height: auto;
}

.shine {
  --shine-color: rgba(255, 255, 255, 0.63);
  --shine-degree: 120deg;
  --shine-effect: linear-gradient(
    var(--shine-degree),
    transparent,
    var(--shine-color),
    transparent
  );

  overflow: hidden;
  position: relative;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--shine-effect);
    animation: shine 4s ease-in-out infinite;
  }
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  25%,
  100% {
    left: 100%;
    transition-property: left;
  }
}
