.managePermissions {
  display: grid;
  grid-template-rows: fit-content() 1fr;
  grid-template-columns: 300px 1fr;
  height: calc(100vh - 48px);
}

.header {
  grid-column: 1 / 3;
  margin: 10px 0;
  padding: 8px 16px;
}

.rolesList {
  overflow-y: auto;
}

.role {
  display: flex;
  align-items: center;
  padding: 3px 8px;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background-color: #3c3c3c;
  }

  &.selected {
    background-color: #575757;
  }
}

.roleColor {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #95a5a6;
}

.permissionsList {
  overflow-y: auto;
  padding: 0 0 0 20px;
}

.roleTitle {
  display: flex;
  align-items: center;
  padding: 3px 8px;
  gap: 8px;

  .roleColor {
    width: 20px;
    height: 20px;
  }
}

.category {
  margin-top: 40px;
  max-width: 500px;

  &:nth-of-type(1) {
    margin-top: 20px;
  }

  & h3 {
    padding: 10px;
    border-bottom: 2px solid #858585;
    background-color: #1e1e1e;
  }
}

.permission {
  padding: 15px 10px;
  border-bottom: 1px solid #4e4e4e;
}

.permissionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
