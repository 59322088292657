.pageContent {
  padding: 0 30px 30px 30px;
  max-width: 1600px;
  margin: 0 auto;

  &.fullWidth {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: 800px) {
    & {
      padding: 0 10px 30px 10px;
    }
  }
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: #fff;
  background-image: linear-gradient(to bottom, rgba(172, 15, 15, 1), rgba(133, 10, 10, 0.7));
  border-bottom: 1px solid rgb(58, 4, 4);
  padding: 0 10px;
}

.topBarInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1600px;
  padding: 0 30px;
}

.discordLink {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-family: 'Ginto Nord', sans-serif;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0)
  );

  img {
    max-width: 100%;
    height: auto;
  }
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-bottom: 30px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1.2em;
    font-weight: bold;
  }
}

.navbarInner {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: url('../../../public/nav-bg.png') repeat-x center center;
  position: relative;
  padding: 0 20px;

  &::before {
    content: '';
    height: 50px;
    width: 21px;
    background: url('../../../public/nav-left.png') no-repeat center center;
    left: -21px;
    position: absolute;
  }

  &::after {
    content: '';
    height: 50px;
    width: 21px;
    background: url('../../../public/nav-right.png') no-repeat center center;
    right: -21px;
    position: absolute;
  }
}

.loginButton {
  border: 0;
  background-color: transparent;
  padding: 8px 10px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
  }

  color: white;
  text-decoration: none;
  font-weight: bold;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}
