.headingBox {
  display: flex;
  position: relative;
  &::before {
    content: '';
    height: 12px;
    width: 100px;
    background: url('../../../../public/heading/heading-decoration-top.png') no-repeat center center;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
  }
  &::after {
    content: '';
    height: 11px;
    width: 100px;
    background: url('../../../../public/heading/heading-decoration-bottom.png') no-repeat center center;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
  }
}

.text {
  flex-grow: 2;
  background: url('../../../../public/heading/heading-bg.png') repeat-x;
  height: 55px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}