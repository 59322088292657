.smallBox {
  position: relative;
}

.content {
  background-color: rgba(0,0,0,0.7);
  margin: -30px 20px 0 20px;
  padding: 40px 10px 20px 10px;
  border-image-slice: 21 21 21 21;
  border-image-width: 20px 20px 20px 20px;
  border-image-outset: 0px 10px 11px 9px;
  border-image-repeat: stretch stretch;
  border-image-source: url("../../../../public/box-borders.png");
  border-style: solid;
}