.container {
  color: white;
  display: grid;
  padding: 10px;
  grid-template-columns: 0.5fr 480px 0.5fr;
  gap: 20px;

  &.viewList {
    grid-template-columns: max-content max-content;
  }

  &.viewBuild {
    grid-template-columns: min-content min-content 480px 1fr;
  }
}

@container (max-width: 800px) {
  .container.viewList {
    grid-template-columns: 1fr;
  }

  .container.viewBuild {
    grid-template-columns: 1fr;
  }
}

.titleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e1e1e;
  padding: 5px 10px;
  border-radius: 10px;
}

.title {
  display: flex;
  gap: 5px;
  align-items: center;
}

.buildView {
  min-height: 100%;
  flex-grow: 0;
  overflow: auto;

  h1 {
    margin: 0 0 10px 0;
    font-size: 1.2em;
  }
}

.buildName {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gear {
  background-color: #e8c59f;
  border: 1px solid #1f1e1e;
  border-radius: 8px;
  background-image: url('https://iqwgqnbvoejzaqceivdm.supabase.co/storage/v1/object/public/images/gear.png?t=2024-06-27T23%3A12%3A44.492Z');
  background-repeat: no-repeat;
  width: 480px;
  height: 480px;
  margin-bottom: 20px;
  position: relative;
  box-shadow: inset 0px 0px 40px 10px rgb(119, 101, 81);
}

.slot {
  width: 105px;
  height: 105px;
  position: absolute;
  border: 1px solid transparent;

  &.buildListSlot {
    max-width: 90px;
    max-height: 90px;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;

    .buildButton & {
      max-width: 75px;
      max-height: 75px;
    }
  }
}

@container (max-width: 800px) {
  .buildListPane {
    .slot {
      &.offhand,
      &.head,
      &.chest,
      &.feet,
      &.cape,
      &.food,
      &.potion {
        display: none;
      }
    }
  }
}

.slotSelected {
  animation: ease-in-out 1s infinite alternate slotSelected;
  box-shadow: 0 0 3px 3px rgba(255, 255, 255, 0.8);
  outline: 2px solid white;
}

@keyframes slotSelected {
  0% {
    box-shadow: 0 0 3px 3px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 3px 7px rgba(255, 255, 255, 0.8);
  }
}

.headSlot {
  top: 49px;
  left: 185px;
}

.armorSlot {
  top: 159px;
  left: 185px;
}

.legsSlot {
  top: 272px;
  left: 185px;
}

.weaponSlot {
  top: 159px;
  left: 58px;
}

.offhandSlot {
  top: 162px;
  left: 314px;
}

.potionSlot {
  top: 287px;
  left: 32px;
}

.foodSlot {
  top: 287px;
  left: 339px;
}

.capeSlot {
  top: 38px;
  left: 339px;
}

.slotItemsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.slotItem {
  width: 100%;
  padding: 10px;
  background-color: #232323;
  border: 1px solid #1e1e1e;
  border-top-color: #3b3b3b;
  border-left-color: #3b3b3b;
  border-radius: 8px;
  box-shadow: inset 2px 2px 2px 2px rgba(0, 0, 0, 0.8);
  color: white;

  h1 {
    min-width: 15px;
    text-align: right;
  }
}

.slotInfoIcon {
  width: 50px;
  height: 50px;
}

.spellList {
  display: flex;
  gap: 10px;
  align-items: center;
}

.items {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.itemContainer {
  height: 100%;
  width: 100%;
  position: relative;
}

.twoHanded {
  opacity: 0.5;
}

.buildButton {
  cursor: pointer;
}

.item {
  border: 1px solid transparent;
  border-radius: 8px;

  &.itemsListItem,
  .buildButton & {
    max-width: 75px;
    max-height: 75px;
  }

  &.selected {
    position: relative;

    img {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: ' ';
      position: absolute;
      left: 5px;
      top: 6px;
      width: 62px;
      height: 62px;
      border-radius: 8px;
      box-shadow: 0 0 3px 3px rgba(25, 255, 0, 0.8);
      z-index: 0;
    }
    //border: 1px solid red;
    //background-color: #ffffff;
    //box-shadow: inset 2px 2px 2px 2px rgba(0, 0, 0, 0.8);
  }

  p {
    text-align: center;
  }

  .buildView & {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    //background-color: #e8c59f;

    &.spellsItemIcon {
      max-width: 50px;
      max-height: 50px;
    }

    h3 {
      color: black;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

.itemImage {
  width: 100%;
}

.placeholder {
  width: 90%;
  height: 90%;
  margin: 5%;
  border: 3px dashed rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

.items2 {
  .item {
    max-width: 75%;
    max-height: 75%;
    position: absolute;
  }

  .item:nth-child(1) {
    top: 0;
    left: 0;
  }

  .item:nth-child(2) {
    bottom: 0;
    right: 0;
  }
}

.items3plus {
  .item {
    max-width: 50%;
    max-height: 50%;
    position: absolute;
  }

  .item:nth-child(1) {
    top: 0;
    left: 0;
  }

  .item:nth-child(2) {
    top: 0;
    right: 0;
  }

  .item:nth-child(3) {
    bottom: 0;
    left: 0;
  }

  .item:nth-child(4) {
    bottom: 0;
    right: 0;
  }
}

.spellIcon {
  opacity: 0.5;
  width: 50px;
  height: 50px;
  margin-left: -5px;

  &.selectedSpell {
    opacity: 1;
  }
}
