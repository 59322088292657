@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #282c34;
  background:
      linear-gradient(to right, rgba(0,0,0,0.4), rgba(0,0,0,0.4)),
      url("../../public/bg4.jpg") no-repeat top center fixed;
  line-height: 1.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Mate SC', serif;
  margin: 0;
}

p {
  margin: 0;
}

@font-face {
  font-family: "Ginto Nord";
  src: url('../../public/fonts/ABCGintoNord-Regular.woff');
  src: url('../../public/fonts/ABCGintoNord-Regular.woff') format('woff'),
    url('../../public/fonts/ABCGintoNord-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ginto Nord";
  src: url('../../public/fonts/ABCGintoNord-Bold.woff');
  src: url('../../public/fonts/ABCGintoNord-Bold.woff') format('woff'),
  url('../../public/fonts/ABCGintoNord-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

