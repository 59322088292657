.markdownEditor {
  color: white;
  background: rgba(255, 255, 255, 0.09);
  width: 100%;
  border-bottom: 1px solid #323232;

  :global(.mdxeditor-root-contenteditable) {
    --baseTextContrast: #fff;
  }

  :global(.mdxeditor-toolbar) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: black;
  }

  blockquote {
    position: relative;

    &:before {
      content: ' ';
      translate: -20px;
      width: 4px;
      height: 100%;
      border-radius: 4px;
      background: rgb(153, 218, 255);
      position: absolute;
    }
  }
}

:global(.mdxeditor-popup-container) {
  color: black;
}

.label {
  color: black;
  font-weight: bold;
}
