.mainGrid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 10px;
  margin-top: 10px;
}

@container (max-width: 768px) {
  .mainGrid {
    grid-template-columns: 1fr;
  }
}
